<template>
  <div class="scp-font-headings scp-antialiased">
    <button
      id="booking-widget"
      @click="showModal()"
      class="scp-w-full scp-px-3 scp-py-1 scp-text-center scp-border scp-rounded-full scp-cursor-pointer scp-border-primary scp-bg-primary scp-text-white scp-font-headings scp-font-bold scp-border-solid"
    >
      Book Now
    </button>
    <transition-group
      enter-active-class="scp-transition-all scp-transition-fastest scp-ease-out-quad"
      leave-active-class="transition-all transition-faster ease-in-quad"
      enter-class="scp-opacity-0 scp-scale-70"
      enter-to-class="scale-100 opacity-100"
      leave-class="scale-100 opacity-100"
      leave-to-class="scp-opacity-0 scp-scale-70"
    >
      <div
        key="modal"
        v-show="modal"
        class="scp-inset-0 scp-w-screen scp-h-screen scp-fixed scp-bg-secondary/30 scp-flex scp-flex-col scp-items-center scp-justify-center scp-gap-4 scp-z-[1003]"
      >
        <div
          class="scp-container scp-relative scp-w-[80%] lg:scp-w-full scp-max-h-[85svh] lg:scp-max-h-[calc(100vh-7rem)] scp-p-2 scp-bg-white scp-shadow-xl md:scp-p-8 xl:scp-w-auto scp-rounded-3xl scp-overflow-y-auto"
        >
          <div
            class="scp-grid scp-items-center scp-w-full scp-grid-cols-3 scp-mx-auto scp-mb-2 md:scp-w-8/12 scp-justify-items-center"
          >
            <div
              class="scp-grid scp-grid-cols-2 scp-col-span-3 scp-w-[68%] scp-translate-y-[38px]"
            >
              <span
                class="scp-w-full scp-h-1 scp-border-t-4 scp-border-primary"
                :class="{
                  'scp-border-primary': stage >= 2,
                  'scp-border-gray-300': stage < 2,
                }"
              ></span>
              <span
                class="scp-w-full scp-h-1 scp-border-t-4"
                :class="{
                  'scp-border-primary': stage >= 3,
                  'scp-border-gray-300 ': stage < 3,
                }"
              ></span>
            </div>
            <div
              class="scp-z-10 scp-grid scp-items-center scp-gap-2 scp-justify-items-center"
            >
              <p
                class="scp-mb-0 scp-text-xs scp-font-bold scp-tracking-widest scp-uppercase scp-text-primary"
              >
                Service
              </p>
              <p
                class="scp-grid scp-w-6 scp-h-6 scp-border-solid scp-p-1 scp-text-xs scp-font-bold scp-leading-none scp-text-center scp-bg-white scp-rounded-full scp-border-3 scp-place-items-center"
                :class="{
                  'scp-text-primary scp-border-primary': stage >= 1,
                  'scp-text-gray-300 scp-border-gray-300 ': stage < 3,
                }"
              >
                <span class="">1</span>
              </p>
            </div>
            <div
              class="scp-z-10 scp-grid scp-items-center scp-gap-2 scp-justify-items-center"
            >
              <p
                class="scp-mb-0 scp-text-xs scp-font-bold scp-tracking-widest scp-uppercase"
                :class="{
                  'scp-text-primary': stage >= 2,
                  'scp-text-gray-300': stage < 2,
                }"
              >
                Location
              </p>
              <p
                class="scp-grid scp-w-6 scp-h-6 scp-border-solid scp-p-1 scp-text-xs scp-font-bold scp-leading-none scp-text-center scp-bg-white scp-rounded-full scp-border-3 scp-place-items-center"
                :class="{
                  'scp-border-primary scp-text-primary': stage >= 2,
                  'scp-border-gray-300 scp-text-gray-300': stage < 2,
                }"
              >
                <span class="">2</span>
              </p>
            </div>
            <div
              class="scp-z-10 scp-grid scp-items-center scp-gap-2 scp-justify-items-center"
            >
              <p
                class="scp-mb-0 scp-text-xs scp-font-bold scp-tracking-widest scp-uppercase"
                :class="{
                  'scp-text-primary': stage >= 3,
                  'scp-text-gray-300': stage < 3,
                }"
              >
                Book
              </p>
              <p
                class="scp-grid scp-w-6 scp-h-6 scp-border-solid scp-p-1 scp-text-xs scp-font-bold scp-leading-none scp-text-center scp-bg-white scp-rounded-full scp-border-3 scp-place-items-center"
                :class="{
                  'scp-border-primary scp-text-primary': stage >= 3,
                  'scp-border-gray-300 scp-text-gray-300': stage < 3,
                }"
              >
                <span class="">3</span>
              </p>
            </div>
          </div>

          <div v-if="stage == 1">
            <div class="scp-mt-2 scp-mb-6 scp-text-center">
              <h1
                class="scp-my-0 scp-text-sm scp-font-bold scp-tracking-wider scp-uppercase scp-text-primary"
              >
                Step 1
              </h1>
              <h2
                class="scp--mt-1 scp-text-2xl md:scp-text-4xl scp-font-headings scp-text-secondary scp-font-bold"
              >
                Select your service
              </h2>
            </div>
            <div v-if="services == []">Loading</div>
            <div
              class="scp-grid scp-gap-4 md:scp-grid-cols-2 lg:scp-grid-cols-3"
            >
              <checkbox
                v-for="service in sortedServices"
                :key="`service-${service.id}`"
                icon-type="service_icon"
                v-bind:item="service"
                v-on:selected="selectService"
                v-bind:selected="service.id == selectedService"
              />
            </div>
          </div>
          <div v-if="stage == 2">
            <div class="scp-mt-2 scp-mb-6 scp-text-center">
              <h1
                class="scp-text-sm scp-font-bold scp-tracking-wider scp-uppercase scp-text-primary"
              >
                Step 2
              </h1>
              <h2
                class="scp--mt-1 scp-text-2xl md:scp-text-4xl scp-font-headings scp-text-secondary scp-font-bold"
              >
                Select your location
              </h2>
              <p
                v-html="
                  `${selectedServiceInfo.title.rendered} is available at ${
                    filteredLocations.length
                  } location${filteredLocations.length > 1 ? 's' : ''}`
                "
              ></p>
            </div>
            <div v-if="locations == []">Loading</div>
            <div
              class="scp-grid scp-gap-4 md:scp-grid-cols-2 lg:scp-grid-cols-3"
            >
              <checkbox
                v-for="location in filteredLocations"
                icon-type="location_logo"
                :key="`service-${location.id}`"
                v-bind:item="location"
                v-on:selected="selectLocation"
                v-bind:selected="location.id == selectedLocation"
              />
            </div>
          </div>
          <div v-if="stage == 3">
            <div class="scp-mt-2 scp-mb-6 scp-text-center">
              <h1
                class="scp-text-sm scp-font-bold scp-tracking-wider scp-uppercase scp-text-primary"
              >
                Step 3
              </h1>
              <h2
                class="scp--mt-1 scp-text-4xl scp-font-headings scp-text-secondary"
              >
                Make your booking
              </h2>
              <p class="scp-mt-4 scp-text-2xl">
                I have chosen
                <span class="scp-font-semibold scp-text-primary">{{
                  selectedServiceInfo.title.rendered
                }}</span>
                at
                <span
                  class="scp-font-semibold scp-text-primary"
                  v-html="selectedLocationInfo.title.rendered"
                >
                </span
                >.
              </p>
            </div>
            <div v-html="bookingInstructions"></div>
          </div>

          <div class="scp-flex scp-mt-8 scp-justify-items-end">
            <button
              v-if="stage > 1"
              @click="previousPage()"
              class="scp-flex scp-items-center scp-gap-2 scp-px-4 scp-py-2 scp-ml-0 scp-mr-auto scp-font-bold scp-transition-all scp-duration-75 scp-bg-white scp-border scp-border-solid scp-rounded-full md:scp-px-12 scp-border-primary scp-text-primary scp-group"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23.12 26"
                class="scp-w-auto scp-h-4 scp-transition scp-duration-75 scp-transform -scp-translate-x-4 scp-opacity-0 scp-text-primary group-hover:scp-opacity-100 group-hover:scp-translate-x-0"
              >
                <path
                  d="M.02 13.203a1.09 1.09 0 0 0 0 .17v.06l.06.13v.06l.08.1 11.56 11.91a.89.89 0 0 0 1.36-.06 1.18 1.18 0 0 0-.06-1.53l-9.73-10H22a1.09 1.09 0 0 0 0-2.16H3.29l9.73-10a1.18 1.18 0 0 0 .06-1.53.88.88 0 0 0-1.36-.06L.16 12.203l-.08.1v.06l-.06.13v.06a.84.84 0 0 0 0 .17 1.22 1.22 0 0 0 0 .44"
                  class="scp-fill-current scp-transform"
                />
              </svg>

              <span class="scp--translate-x-2 group-hover:scp-translate-x-0"
                >Previous</span
              >
            </button>
            <button
              v-if="stage < 3"
              @click="nextPage()"
              class="scp-flex scp-items-center scp-border-solid scp-gap-2 scp-px-4 scp-py-2 scp-ml-auto scp-mr-0 scp-font-bold scp-text-white scp-rounded-full md:scp-px-12 scp-group scp-bg-primary scp-border-primary scp-border"
            >
              <span
                class="group-hover:-scp-translate-x-2 scp-translate-x-2 scp-transition-transform scp-duration-75"
              >
                Next
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23.12 26"
                class="scp-w-auto scp-h-4 scp-translate-x-4 scp-opacity-0 scp-duration-75 scp-transition-transform group-hover:scp-opacity-100 group-hover:scp-translate-x-0 scp-transform"
              >
                <path
                  d="M1474.34 852.59a1.09 1.09 0 0 0 0-.17v-.06l-.06-.13v-.06l-.08-.1-11.56-11.91a.89.89 0 0 0-1.36.06 1.18 1.18 0 0 0 .06 1.53l9.73 10h-18.71a1.09 1.09 0 0 0 0 2.16h18.71l-9.73 10a1.18 1.18 0 0 0-.06 1.53.88.88 0 0 0 1.36.06l11.56-11.91.08-.1v-.06l.06-.13v-.06a.84.84 0 0 0 0-.17 1.22 1.22 0 0 0 0-.44"
                  transform="translate(-1451.24 -839.81)"
                  class="scp-fill-current"
                />
              </svg>
            </button>
            <a
              class="scp-flex scp-items-center scp-border-solid scp-gap-2 scp-px-4 scp-py-2 scp-ml-auto scp-mr-0 scp-font-bold scp-text-white scp-rounded-full md:scp-px-12 scp-group scp-bg-primary"
              v-if="stage == 3"
              target="_blank"
              :href="selectedLocationInfo.acf.booking_link"
            >
              Book Now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23.12 26"
                class="scp-w-auto scp-h-4 scp-text-white scp-transition scp-duration-75 scp-translate-x-4 scp-opacity-0 group-hover:scp-opacity-100 group-hover:scp-translate-x-0"
              >
                <path
                  d="M1474.34 852.59a1.09 1.09 0 0 0 0-.17v-.06l-.06-.13v-.06l-.08-.1-11.56-11.91a.89.89 0 0 0-1.36.06 1.18 1.18 0 0 0 .06 1.53l9.73 10h-18.71a1.09 1.09 0 0 0 0 2.16h18.71l-9.73 10a1.18 1.18 0 0 0-.06 1.53.88.88 0 0 0 1.36.06l11.56-11.91.08-.1v-.06l.06-.13v-.06a.84.84 0 0 0 0-.17 1.22 1.22 0 0 0 0-.44"
                  transform="translate(-1451.24 -839.81)"
                  class="scp-fill-current"
                />
              </svg>
            </a>
          </div>
        </div>
        <button
          key="exit"
          @click="showModal()"
          class="scp-flex scp-gap-2 scp-cursor-pointer scp-group scp-items-center scp-tracking-normal scp-self-center scp-justify-center scp-text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="scp-w-8 scp-h-8 scp-fill-none group-hover:scp-fill-secondary"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="{2}"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          Close
        </button>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Checkbox from "./Checkbox.vue";
export default {
  components: { Checkbox },
  name: "booking-widget",
  data: () => ({
    stage: 1,
    modal: false,
    services: [],
    locations: [],
    selectedService: null,
    selectedLocation: null,
  }),
  methods: {
    previousPage() {
      this.stage > 0 ? this.stage-- : (this.stage = 0);
    },
    nextPage() {
      if (this.stage == 1 && this.selectedService == null) {
        alert("Please select a service");
      }
      if (
        this.stage == 1 &&
        this.selectedService != null &&
        this.selectedServiceInfo.acf.booking_link != undefined &&
        this.selectedServiceInfo.acf.booking_link != ""
      ) {
        window.open(this.selectedServiceInfo.acf.booking_link, "_blank");
        window.dataLayer.push({
          event: "openBookingLink",
          service: this.selectedServiceInfo.title.rendered,
          location: "No Location",
        });
      } else if (this.stage == 2 && this.selectedLocation == null) {
        alert("Please select a location");
      } else if (
        this.selectedService != null &&
        this.selectedLocation != null
      ) {
        this.stage++;
        window.open(this.selectedLocationInfo.acf.booking_link, "_blank");

        window.dataLayer.push({
          event: "openBookingLink",
          service: this.selectedServiceInfo.title.rendered,
          location: this.selectedLocationInfo.title.rendered,
        });
      } else {
        this.stage++;
      }
    },
    showModal() {
      this.modal = !this.modal;
    },
    selectService(service) {
      this.selectedService = service;
      window.dataLayer.push({
        event: "selectService",
        service: this.selectedServiceInfo.title.rendered,
      });
    },
    selectLocation(event) {
      this.selectedLocation = event;
      window.dataLayer.push({
        event: "selectLocation",
        location: this.selectedLocationInfo.title.rendered,
      });
    },
    async fetchServices() {
      await fetch(
        "/wp-json/wp/v2/service_post?per_page=50&_embed&acf_format=standard"
      )
        .then((x) => x.json())
        .then((x) => {
          this.services = x.filter(
            (y) => y.acf.hide_from_booking_journey != true
          );
        });
    },
    async fetchLocations() {
      await fetch(
        "/wp-json/wp/v2/location_post?per_page=50&_embed&acf_format=standard"
      )
        .then((x) => x.json())
        .then((x) => {
          this.locations = x;
          x.forEach((location) => {
            location.acf.services_offered.forEach((y) => {
              let service = this.services.find((service) => service.id == y.ID);
              if (service && service.locations) {
                service.locations.push(location.id);
              } else if (service) {
                service.locations = [location.id];
              }
            });
          });
        });
    },
  },
  async mounted() {
    window.dataLayer = window.dataLayer || [];
    await this.fetchServices();
    await this.fetchLocations();
  },

  computed: {
    filteredLocations() {
      if (
        this.selectedService !== null &&
        this.selectedServiceInfo.locations != undefined
      )
        return this.locations.filter((loc) =>
          this.selectedServiceInfo.locations.includes(loc.id)
        );
    },
    locationServies() {
      return this.locations.map((x) => ({
        location: x.id,
        services: x.acf.services_offered.map((y) => y.ID),
      }));
    },
    bookingInstructions() {
      return this.selectedLocationInfo.acf.booking_instructions;
    },
    sortedServices() {
      if (this.services)
        return this.services.sort((a, b) => {
          return a.title.rendered > b.title.rendered ? 1 : -1;
        });
    },

    selectedServiceInfo() {
      return this.services.find((x) => x.id == this.selectedService);
    },
    selectedLocationInfo() {
      return this.locations.find((x) => x.id == this.selectedLocation);
    },
  },
};
</script>
