import Vue from "vue";

import BookingWidget from "../vue/BookingWidget.vue";

const app = new Vue({
  el: "#page",
  components: {
    BookingWidget,
  },
});

const bookBtns = document.querySelectorAll('a[href*="#book');
bookBtns.forEach((btn) =>
  btn.addEventListener("click", function (e) {
    e.preventDefault();
    document.querySelector("#booking-widget").click();
  })
);
