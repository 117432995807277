<template>
  <button
    @click="selectItem()"
    class="scp-flex scp-justify-between scp-rounded-full scp-p-2 scp-gap-2 scp-items-center scp-px-4 scp-border-none scp-bg-[#f0f0f0]"
    :class="{
      'scp-border-primary scp-shadow': selected,
      'scp-shadow-sm': !selected,
    }"
  >
    <span class="scp-flex scp-gap-2 scp-items-center scp-justify-center">
      <img
        v-if="item.acf[iconType]"
        :src="item.acf[iconType]"
        :alt="item.title.rendered"
        class="scp-object-contain scp-w-auto scp-h-8"
      />
      <svg
        v-else
        class="scp-w-auto scp-h-6 scp-text-primary"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 37.85 36.83"
      >
        <path
          class="scp-fill-current"
          d="M522.83 556.61a3.06 3.06 0 0 0 0-4.39l-5.62-5.47a3.31 3.31 0 0 0-4.49 0ZM497 581.79l-10.12-9.85a3 3 0 0 0 0 4.38l5.62 5.48a3.26 3.26 0 0 0 4.5 0ZM488 566.46a3 3 0 0 0 0 4.38l10.12 9.86a3.22 3.22 0 0 0 4.49 0ZM507.09 547.84l14.62 14.24a3 3 0 0 0 0-4.38l-10.12-9.86a3.32 3.32 0 0 0-4.5 0Z"
          transform="translate(-485.92 -545.87)"
        />
        <path
          class="scp-fill-current"
          d="m19.48 13.47-5.61 5.48 4.49 4.38 5.62-5.48-4.5-4.38z"
        />
        <path
          class="scp-fill-current"
          d="M508.22 575.22 493.6 561a3.32 3.32 0 0 0-4.5 0 3 3 0 0 0 0 4.38l14.62 14.24a3.26 3.26 0 0 0 4.5 0 3.06 3.06 0 0 0 0-4.39M520.58 563.18 506 548.94a3.32 3.32 0 0 0-4.5 0 3 3 0 0 0 0 4.38l14.62 14.24a3.22 3.22 0 0 0 4.49 0 3 3 0 0 0 0-4.38"
          transform="translate(-485.92 -545.87)"
        />
      </svg>

      <span
        class="scp-col-span-2 scp-text-base scp-font-bold scp-text-left"
        :class="{
          'scp-text-primary': selected,
          'scp-text-secondary': !selected,
        }"
        v-html="item.title.rendered"
      >
      </span>
    </span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50.04 50.04"
      class="scp-flex-grow-0 scp-flex-shrink-0 scp-w-4 scp-h-4 scp-ml-auto md:scp-w-8 md:scp-h-8 lg:scp-block"
      :class="{
        'scp-text-primary ': selected,
        'scp-text-secondary scp-hidden': !selected,
      }"
    >
      <path
        d="M739.91 588.31a24 24 0 1 0-24-24 24 24 0 0 0 24 24"
        transform="translate(-714.89 -539.27)"
        class="scp-fill-white"
      />
      <path
        d="M739.91 588.31a24 24 0 1 0-24-24 24 24 0 0 0 24 24Z"
        transform="translate(-714.89 -539.27)"
        class="scp-stroke-current scp-stroke-2 scp-fill-none"
      />
      <path
        d="m750.42 558.43-3.1-3.1a.43.43 0 0 0-.63 0l-10.77 10.77-2.79-2.79a.45.45 0 0 0-.63 0l-3.1 3.1a.45.45 0 0 0 0 .63l6.21 6.21a.44.44 0 0 0 .62 0l14.19-14.19a.45.45 0 0 0 0-.63"
        transform="translate(-714.89 -539.27)"
        class="scp-fill-current"
        v-show="selected"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "scp-checkbox",

  props: {
    iconType: {
      type: String,
    },
    selected: {
      type: Boolean,
      default: () => {
        false;
      },
    },
    item: {
      default: () => ({
        title: {
          rendered: "title",
        },
      }),
    },
  },
  methods: {
    selectItem() {
      this.$emit("selected", this.item.id);
    },
  },
};
</script>
